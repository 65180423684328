import { TimePicker, type TimePickerProps } from "antd";
import type { Dayjs } from "dayjs";
import { FC } from "react";
import styled from "styled-components";

interface Props {
    label?: string;
    disabled?: boolean;
    placeholder?: string;
    defaultValue?: Dayjs;
    value?: Dayjs | null;
    onChange: TimePickerProps["onChange"];
}

const CTimePicker: FC<Props> = ({
    label,
    disabled,
    placeholder,
    value,
    onChange,
    defaultValue
}) => {
    return (
        <Container>
            {label && <label>{label}</label>}
            <TimePicker
                size="large"
                format="HH:mm"
                defaultValue={defaultValue}
                disabled={disabled}
                value={value}
                placeholder={placeholder}
                onChange={onChange}
            />
        </Container>
    );
};

export default CTimePicker;

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 400px;

    label {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        margin-bottom: 8px;
    }
`;
