import styled from "styled-components";

const ConsentForm = () => {
    return (
        <Container>
            <img src="/images/consent.png" alt="consent_form" />
        </Container>
    );
};

export default ConsentForm;

const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: auto;
    }

    @media (max-width: 840px) {
        img {
            width: 100%;
        }
    }
`;
