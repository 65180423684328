import type { CheckboxChangeEvent } from "antd/es/checkbox";
import type { ChangeEvent, FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { showNotification } from "../../helpers/showNotification";
import { checkEmailField } from "../../helpers/validation";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { SendOtpSource } from "../../http/api";
import { UserRole } from "../../models/authTypes";
import { RowContainer } from "../../pages/login/styles";
import { RoutesNames } from "../../routes/enum";
import { sendVerifyCodeAction } from "../../store/actions/authActions";
import { authSlice } from "../../store/reducers/AuthSlice";
import { CButton, CCheckbox, CInput, CSelect } from "../common";
import { ErrorMessage } from "../ErrorMessage";
import { countries } from "../profile/constants";

interface Props {
    handleChangeStep: () => void;
}

export const FirstStep: FC<Props> = ({ handleChangeStep }) => {
    const { registration, error, isLoading, role } = useAppSelector((state) => state.authReducer);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const onCountryChange = (value: string) => {
        dispatch(
            authSlice.actions.updateRegisterForm({ ...registration, location_country: value })
        );
    };

    const getValueFromFields = (e: ChangeEvent<HTMLInputElement> | CheckboxChangeEvent) => {
        const name: string = e.target.name as string;
        let value: string | boolean = e.target.value;

        if (name === "terms" || name === "personalData") {
            value = e.target.checked;
        }

        dispatch(authSlice.actions.updateRegisterForm({ ...registration, [name]: value }));
    };

    const onSubmitData = async () => {
        try {
            await dispatch(sendVerifyCodeAction(registration.email, SendOtpSource.REGISTER));
            handleChangeStep();
        } catch (error) {
            showNotification(t("Invalid phone number or email"), "error");
        }
    };

    const checkEmail = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (value.includes("@")) {
            const isValid = checkEmailField(value);
            !isValid &&
                dispatch(
                    authSlice.actions.setErrorMessage(t("The mailbox is not entered correctly"))
                );
        }
    };

    return (
        <RowContainer>
            <CInput
                label={t("Phone/E-mail")}
                name="email"
                value={registration.email}
                validateStatus={error ? "error" : ""}
                placeholder={t("Enter your phone number or email address")}
                onChange={getValueFromFields}
                onBlur={checkEmail}
            />
            <CSelect
                value={registration.location_country || undefined}
                options={countries()}
                placeholder={t("Select country")}
                label={t("Location country")}
                onChange={onCountryChange}
            />
            <CCheckbox
                title={
                    <span>
                        {t("I read and accept")}{" "}
                        <Link to={RoutesNames.TERMS}>{t("Privacy policies")}</Link> {t("and")}{" "}
                        <Link to={RoutesNames.FAQ}>{t("Service rules")}</Link>
                    </span>
                }
                checked={registration.terms}
                name="terms"
                onChange={getValueFromFields}
            />
            {role === UserRole.PATIENT && (
                <CCheckbox
                    title={
                        <span>
                            {t("I agree to the")}{" "}
                            <Link to={RoutesNames.CONSENT_FORM}>
                                {t("processing of personal data")}
                            </Link>
                        </span>
                    }
                    checked={registration.personalData}
                    name="personalData"
                    onChange={getValueFromFields}
                />
            )}
            <CButton
                onClick={onSubmitData}
                loading={isLoading}
                disabled={
                    !registration.location_country ||
                    !registration.email ||
                    !registration.terms ||
                    !registration.personalData
                }
            >
                {t("Continue registration")}
            </CButton>
            {error && <ErrorMessage message={error} />}
        </RowContainer>
    );
};
