import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type {
    DoctorDocsResponse,
    DoctorInfoResponse,
    IDoctorState,
    IPatientData,
    IPriceConfig,
    SpecializationResponse
} from "../../models/doctorTypes";

const initialState: IDoctorState = {
    specializations: [],
    documents: [],
    info: {
        bio: null,
        education: "",
        about: "",
        work_bio: "",
        id: "",
        license: null
    },
    priceConfig: {
        consultation_length: 40,
        rest_length: 10,
        consultation_price: 0,
        consultation_price_updatedAt: null,
        id: "",
        recipe_price: 0,
        recipe_price_updatedAt: null,
        season_price: 0,
        season_price_updatedAt: null,
        user_id: ""
    },
    isLoading: false,
    actionRequesting: false,
    error: "",
    patients: []
};

export const doctorSlice = createSlice({
    name: "doctor",
    initialState,
    reducers: {
        setError(state, action: PayloadAction<string>) {
            state.isLoading = false;
            state.actionRequesting = false;
            state.error = action.payload;
        },
        setIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setRequesting(state, action: PayloadAction<boolean>) {
            state.actionRequesting = action.payload;
        },
        setSpecializations(state, action: PayloadAction<SpecializationResponse[]>) {
            state.isLoading = false;
            state.error = "";
            state.specializations = action.payload;
        },
        setDocuments(state, action: PayloadAction<DoctorDocsResponse[]>) {
            state.isLoading = false;
            state.error = "";
            state.documents = action.payload;
        },
        setDoctorInfo(state, action: PayloadAction<DoctorInfoResponse>) {
            state.isLoading = false;
            state.error = "";
            state.info = action.payload;
        },
        setPriceConfig(state, action: PayloadAction<IPriceConfig>) {
            state.isLoading = false;
            state.actionRequesting = false;
            state.error = "";
            state.priceConfig = action.payload;
        },
        setPatients(state, action: PayloadAction<Array<IPatientData>>) {
            state.isLoading = false;
            state.error = "";
            state.patients = action.payload;
        }
    }
});

export default doctorSlice.reducer;
