import { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Loader } from "./components/common/Loader";
import { LoginLayout } from "./components/LoginLayout";
import { RootLayout } from "./components/RootLayout";
import { useAuth } from "./contexts/AuthContextProvider";
import { useAppDispatch, useAppSelector } from "./hooks/redux";
import { useChatStream } from "./hooks/useChatStream";
import ConsentForm from "./pages/consent";
import PlatformRules from "./pages/FAQ";
import HomePage from "./pages/home";
import { LoginPage } from "./pages/login";
import { RegistrationPage } from "./pages/registration";
import { ResetPasswordPage } from "./pages/reset";
import TermsAndConditions from "./pages/teerms";
import { doctorRoutes } from "./routes/doctorRoutes";
import { RoutesNames } from "./routes/enum";
import { patientRoutes } from "./routes/patientRoutes";
import { videoSlice } from "./store/reducers/VideoSlice";

const DoctorVideoChatPage = lazy(() => import("./pages/video/Doctor"));
const PatientVideoChatPage = lazy(() => import("./pages/video/Patient"));

const App = () => {
    const { loading } = useAuth();
    const dispatch = useAppDispatch();
    const { sessionId } = useAppSelector((state) => state.videoChatReducer);
    const videoChatActions = useChatStream(sessionId, {
        onConnectionOpen: () => dispatch(videoSlice.actions.setWebcamActive(true)),
        onConnectionClose: () => dispatch(videoSlice.actions.setCompletedView(true)),
        onConnectionDisconnected: () => dispatch(videoSlice.actions.setErrorConnectView(true)),
        onOtherConnectionClose: () => console.log("connection closed with other user")
    });

    if (loading) {
        return <Loader width="100vw" height="100vh" />;
    }

    return (
        <Routes>
            <Route path={RoutesNames.HOME} element={<HomePage />} />
            <Route path={RoutesNames.LOGIN} element={<LoginLayout />}>
                <Route path={RoutesNames.LOGIN} element={<LoginPage />} />
                <Route path={RoutesNames.REGISTRATION} element={<RegistrationPage />} />
                <Route path={RoutesNames.RESET} element={<ResetPasswordPage />} />
            </Route>
            <Route path={RoutesNames.FAQ} element={<PlatformRules />} />
            <Route path={RoutesNames.TERMS} element={<TermsAndConditions />} />
            <Route path={RoutesNames.CONSENT_FORM} element={<ConsentForm />} />

            <Route
                path={RoutesNames.DOCTOR}
                element={<RootLayout localVideoRef={videoChatActions.remoteStreamState} />}
            >
                <Route
                    path={RoutesNames.VIDEO}
                    element={<DoctorVideoChatPage chatActions={videoChatActions} />}
                />
                {doctorRoutes.map((route) => (
                    <Route key={route.id} path={route.path} element={route.element} />
                ))}
            </Route>

            <Route
                path={RoutesNames.PATIENT}
                element={<RootLayout localVideoRef={videoChatActions.remoteStreamState} />}
            >
                <Route
                    path={RoutesNames.VIDEO}
                    element={<PatientVideoChatPage chatActions={videoChatActions} />}
                />
                {patientRoutes.map((route) => (
                    <Route key={route.id} path={route.path} element={route.element} />
                ))}
            </Route>
            <Route path="*" element={<Navigate to={RoutesNames.HOME} />} />
        </Routes>
    );
};

export default App;
