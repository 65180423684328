import type { UserRole } from "./authTypes";

export enum UserStatus {
    APPROVED = "approved",
    ON_REVIEW = "on review",
    NOT_ACTIVE = "not active",
    NEED_UPDATE = "need update"
}

export interface UserResponse {
    firstName: string | null;
    lastName: string | null;
    surName: string | null;
    country: string;
    city: string | null;
    birthdate: string | null;
    languages: Array<string>;
    sex: string | null;
    role: UserRole | "";
    location_city?: string | null;
    location_country?: string | null;
    location_timezone?: string | null;
    isFormActual?: boolean;
    phone: string | null;
    email: string | null;
    activeStatus: UserStatus;
    id: string;
    avatarUrl: string | null;
    originalUrl: string | null;
    publicUrl: string | null;
    refreshToken: string;
    createdAt: string;
    updatedAt: string;
    blockReason: string | null;
    chatAccept: boolean;
    chatAcceptDate: string | null;
}

export enum NotifyEvents {
    REMINDER = "reminder",
    RECIPE_READY = "recipe ready",
    INFO = "info"
}

export interface INotifyData {
    msg_en: string;
    msg_ua: string;
    date: string;
    doctor: {
        id: string;
        firstName: string;
        lastName: string;
        surName: string;
    };
    consultation_id: string;
    notification_id: string;
    patient: {
        id: string;
        firstName: string;
        lastName: string;
        surName: string;
    };
    start: string;
    type: "video chat" | "text chat";
}

export interface INotification {
    createdAt: string;
    data: INotifyData;
    event: NotifyEvents;
    id: string;
    status: "new" | "viewed";
    to_user: string;
    updatedAt: string;
}

export interface IUserState {
    isLoading: boolean;
    error: string;
    user: UserResponse;
    collapsedNavbar: boolean;
    notification: Array<INotification>;
}

export interface IUserProfile {
    firstName: string | null;
    lastName: string | null;
    surName: string | null;
    country: string;
    city: string | null;
    birthdate: string | null;
    languages: Array<string>;
    sex: string | null;
    role: UserRole | "";
    phone: string | null;
    email: string | null;
    activeStatus: UserStatus;
    location_city?: string | null;
    location_country?: string | null;
    location_timezone?: string | null;
    blockReason: string | null;
    chatAccept: boolean;
    chatAcceptDate: string | null;
}
