export enum RecipeStatus {
    DONE = "done",
    NEED_PAYMENTS = "need payments",
    REQUEST = "request",
    TMP = "template"
}

export interface IRecipeMedicamentState {
    name: string;
    unit: string;
    release_form: string;
    dosage: number;
    // period: number;
    period_text: string;
    receptions_per_day: number;
    diagnosis: string;
    comment: string;
    reciple_id?: string;
    id?: string;
}

export interface IRecipe {
    chronic_diseases?: string;
    end_date?: string;
    country?: string;
    status: RecipeStatus;
    price: number;
    elements: Array<IRecipeMedicamentState>;
    consultation_id?: string;
    doctor_id: string;
    patient_id: string;
    request_id?: string;
}

export interface IRecipeResponse extends IRecipe {
    id: string;
    recipleNumber: number;
    createdAt: string;
    updatedAt: string;
    doctor: {
        firstName: string;
        id: string;
        lastName: string;
        specializations: Array<string>;
        surName: string;
    };
    patient: {
        birthdate: string;
        email: string;
        firstName: string;
        id: string;
        lastName: string;
        phone: string;
        surName: string;
    };
}

export interface IRecipeRequest extends IRecipe {
    createdAt: string;
    id: string;
    recipleNumber: number;
    updatedAt: string;
}
