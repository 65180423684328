export enum UserRole {
    DOCTOR = "doctor",
    PATIENT = "patient"
}

export interface AuthState {
    role: UserRole;
    error: string;
    isLoading: boolean;
    login: ILoginState;
    registration: IRegisterState;
    reset: IResetState;
}

export interface ILoginState {
    email: string;
    password: string;
    savedInSystem: boolean;
}

export interface ILoginData {
    login: string;
    role: UserRole;
    password: string;
}

export interface IRegisterState {
    email: string;
    location_country: string;
    terms: boolean;
    personalData: boolean;
    code: string;
    password: string;
    repeated_password: string;
    savedInSystem: boolean;
}

export interface RegistrationData {
    login: string;
    location_country: string;
    password: string;
    role: UserRole;
}

export interface IResetState {
    email: string;
    code: string;
    role: UserRole;
    password: string;
    repeated_password: string;
}

export interface RefreshTokenState {
    accessToken: string;
    refreshToken: string;
    role: UserRole;
}
