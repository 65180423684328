import { useAppDispatch, useAppSelector } from "./redux";
import Pusher, { Channel } from "pusher-js";
import { useEffect } from "react";
import { type INotification, NotifyEvents, type INotifyData } from "../models/userTypes";
import { userSlice } from "../store/reducers/UserSlice";
import { showNotification } from "../helpers/showNotification";
import dayjs from "dayjs";
import { uid } from "uid";
import { UserRole } from "../models/authTypes";
import { t } from "i18next";
import { getUserFullName } from "../helpers/getUserFullName";
import { useLanguage } from "./useLanguage";

const APP_KEY = process.env.REACT_APP_PUSHER_KEY || "";

export const usePusher = () => {
    const dispatch = useAppDispatch();
    const { user } = useAppSelector((state) => state.userReducer);
    const { language } = useLanguage();

    const role = user.role === UserRole.DOCTOR ? UserRole.PATIENT : UserRole.DOCTOR;

    const pusher: Pusher = new Pusher(APP_KEY, {
        cluster: process.env.REACT_APP_PUSHER_CLUSTER as string
    });

    const getNotificationTitle = (data: INotifyData, role: UserRole, event: NotifyEvents) => {
        let message = "";

        const messageDate = dayjs(data?.date).calendar(null, {
            sameDay: `[${t("Today")}]`,
            nextDay: `[${t("Tomorrow")}]`,
            nextWeek: "dddd",
            lastDay: `[${t("Yesterday")}]`,
            lastWeek: `[${t("Last")}] dddd`,
            sameElse: "DD/MM/YYYY"
        });

        const name = getUserFullName(data?.[role]);

        if (event === NotifyEvents.REMINDER) {
            let consultationType = "онлайн-візит";

            if (data?.type === "text chat") {
                consultationType = "візит через текстовий чат";
            }
            message = `${messageDate} o ${dayjs(+data?.start).format(
                "HH:mm"
            )} ${consultationType} до ${t(role)} ${name}`;
        } else if (event === NotifyEvents.INFO) {
            message = data.msg_ua;
            if (language === "en") message = data.msg_en;
        } else {
            message = "Ваш рецепт на ліки вже готовий";
        }

        return message;
    };

    useEffect(() => {
        if (user.id) {
            const channel: Channel = pusher.subscribe(user.id);
            channel.bind(NotifyEvents.REMINDER, (data: INotifyData) => {
                console.log("REMINDER", data);
                const newNotification: INotification = {
                    createdAt: dayjs().format(),
                    data,
                    event: NotifyEvents.REMINDER,
                    id: uid(),
                    status: "new",
                    to_user: user.id,
                    updatedAt: dayjs().format()
                };

                dispatch(userSlice.actions.updateNotification(newNotification));
                showNotification(getNotificationTitle(data, role, NotifyEvents.REMINDER), "info");
            });
            channel.bind(NotifyEvents.RECIPE_READY, (data: INotifyData) => {
                console.log("PRESCRIPTIONS", data);
                const newNotification: INotification = {
                    createdAt: dayjs().format(),
                    data,
                    event: NotifyEvents.RECIPE_READY,
                    id: uid(),
                    status: "new",
                    to_user: user.id,
                    updatedAt: dayjs().format()
                };

                dispatch(userSlice.actions.updateNotification(newNotification));
                showNotification(getNotificationTitle(data, role, NotifyEvents.REMINDER), "info");
            });
            channel.bind(NotifyEvents.INFO, (data: INotifyData) => {
                console.log("INFO", data);
                const newNotification: INotification = {
                    createdAt: dayjs().format(),
                    data,
                    event: NotifyEvents.INFO,
                    id: uid(),
                    status: "new",
                    to_user: user.id,
                    updatedAt: dayjs().format()
                };

                dispatch(userSlice.actions.updateNotification(newNotification));
                showNotification(getNotificationTitle(data, role, NotifyEvents.INFO), "info");
            });
        }
    }, [user.id]);
};
